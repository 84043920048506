<template>
  <v-container id="user-profile-view" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <material-card color="primary" icon="mdi-account-outline">
          <!-- <template #title>
            Edit Profile — <small class="text-body-1">Complete your profile</small>
          </template> -->

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="4">
                  <label class="label">Company</label>
                  <p v-text="this.$store.state.myself.company"></p>
                </v-col>

                <v-col cols="12" md="4">
                  <label class="label">Username</label>
                  <p v-text="this.userProfile.username"></p>
                </v-col>

                <v-col cols="12" md="4">
                  <label class="label">Email</label>
                  <p v-text="this.userProfile.email"></p>
                </v-col>

                <v-col cols="12" md="4">
                  <label class="label">First Name</label>
                  <p v-text="this.userProfile.firstName"></p>
                </v-col>

                <v-col cols="12" md="4">
                  <label class="label">Last Name</label>
                  <p v-text="this.userProfile.lastName"></p>
                </v-col>

                <v-col cols="12">
                  <label class="label">Address</label>
                  <p v-text="this.$store.state.myself.address"></p>
                </v-col>

                <v-col cols="12" md="4">
                  <label class="label">City</label>
                  <p v-text="this.$store.state.myself.city"></p>
                </v-col>

                <v-col cols="12" md="4">
                  <label class="label">Country</label>
                  <p v-text="this.$store.state.myself.country"></p>
                </v-col>

                <v-col cols="12" md="4">
                  <label class="label">Postal Code</label>
                  <p v-text="this.$store.state.myself.PostalCode"></p>
                </v-col>

                <!-- <v-col cols="12">
                  <v-textarea
                    color="purple"
                    label="About Me"
                    value="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                  />
                </v-col> -->

                <!-- <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="primary"
                    min-width="150"
                  >
                    Update Profile
                  </v-btn>
                </v-col> -->
              </v-row>
            </v-container>
          </v-form>
        </material-card>
      </v-col>

      <v-col cols="12" md="4">
        <app-card class="mt-4 text-center">
          <v-avatar size="200">
            <v-img  :src="getPic()"></v-img>
          </v-avatar>
          <v-card-text class="text-center">
            <h6
              class="text-h6 mb-2 text--secondary"
              v-text="this.$store.state.myself.role"
            ></h6>

            <h4
              class="text-h4 mb-3 text--primary"
              v-text="this.$store.state.myself.username"
            ></h4>

            <!-- <p class="text--secondary">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ratione dolorem deserunt veniam tempora magnam quisquam quam error iusto cupiditate ducimus, et eligendi saepe voluptatibus assumenda similique temporibus placeat animi dicta?
            </p>

            <v-btn
              class="mr-0"
              color="primary"
              min-width="100"
              rounded
            >
              Follow
            </v-btn> -->
          </v-card-text>
        </app-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { sync } from "vuex-pathify";

export default {
  name: "UserProfileView",
  computed: {
    ...sync("auth", ["userProfile"]),
  },
  methods: {
    getPic() {
      return !!this.userProfile && !!this.userProfile.picture? this.userProfile.picture : require('@/assets/profile_empty.svg')
    },
  }
};
</script>



<style>
.label {
  font-size: 15px;
  color: grey;
}
</style>
